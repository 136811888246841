<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div>
    <!-- Start head title -->
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="12" xs="12">
        <span class="font-weight-black ml-1" style="font-size: 20px" :style="isDark ? '' : 'color: rgb(43, 42, 42)'">
          {{ $t('Marketing Calendar') }}
        </span>
      </v-col>
    </v-row>
    <!-- End head title -->

    <!-- Edit campaign modal -->
    <edit-campaign-dialog
      v-model="showEditCampaignModal"
      :value_isForUpdate="true"
      :value_ID_for_editing="campaignId"
    />

    <v-row class="mt-3" :style="$vuetify.breakpoint.lgAndUp ? 'flex-wrap: nowrap' : ''">
      <v-col cols="12" lg="8" xl="9">
        <v-row>
          <!-- Start button left -->
          <!-- Campaign Settings Button -->
          <v-col cols="auto">
            <v-btn depressed class="text-capitalize cmd-button" outlined @click="campaignSettings">
              <v-icon dense color="black">
                {{ icons.mdiTune }}
              </v-icon>
              {{ $t('Settings') }}
            </v-btn>
          </v-col>
          <!-- End button left -->

          <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

          <!-- Start button Right -->
          <!-- Start button calendar & list -->
          <v-col xs="auto" align="end">
            <v-btn-toggle v-model="toggle_exclusive">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined>
                    <v-icon dense :color="toggle_exclusive === 0 ? 'primary' : 'black'" dark v-bind="attrs" v-on="on">
                      {{ icons.mdiCalendarMonthOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Calendar View</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined>
                    <v-icon dense :color="toggle_exclusive === 1 ? 'primary' : 'black'" dark v-bind="attrs" v-on="on">
                      {{ icons.mdiFormatListText }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>List View</span>
              </v-tooltip>
            </v-btn-toggle>
          </v-col>
          <!-- End button calendar & list -->

          <!-- Start search -->
          <v-col cols="12" md="auto">
            <v-text-field
              v-model="search"
              :prepend-inner-icon="icons.mdiMagnify"
              label="Search campaign"
              hide-details
              dense
              outlined
            >
            </v-text-field>
          </v-col>
          <!-- End search -->
          <!-- End button Right -->
        </v-row>

        <!-- Start Side bar for created post -->
        <post-content-list v-if="$vuetify.breakpoint.mdAndDown" class="px-0 pt-8 pb-2" />
        <!-- End Side bar for created post -->

        <!-- Start Calendar -->
        <calendar v-if="toggle_exclusive === 0" />
        <!-- End Calendar -->
        <!-- Start List View -->
        <transition name="fade">
          <v-data-table
            v-if="toggle_exclusive === 1"
            :headers="tableHeaders"
            :items="schedules"
            item-key="id"
            :search="search"
            :items-per-page="10"
            :loading="false"
            loading-text="Loading... Please wait"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :footer-props="{
              'items-per-page-text': $t('rowsPerPageLabel'),
              'items-per-page-options': [5, 10, 30, 50, 100],
            }"
            class="mt-10"
          >
            <template v-slot:item.id="{ item }">
              {{ schedules.findIndex(c => c.id === item.id) + 1 }}
            </template>
            <template v-slot:item.actions="{ item }">
              <!-- Buttons action Shown on display size md and up-->
              <v-row v-if="$vuetify.breakpoint.mdAndUp" no-gutters>
                <v-col>
                  <v-btn
                    class="action-btn me-4"
                    outlined
                    :style="isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
                    color="#F15858"
                    @click="
                      () => {
                        editSchedule(item.id)
                      }
                    "
                  >
                    <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="item.statusSchedule === 'Published'"
                    class="action-btn me-4"
                    outlined
                    :style="isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
                    color="#F15858"
                    @click="toStatistic(item)"
                  >
                    <v-icon>{{ icons.mdiPoll }}</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="item.statusSchedule === 'Published'"
                    class="action-btn"
                    outlined
                    :style="isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
                    color="#F15858"
                    @click="deleteItem(item.id)"
                  >
                    <v-icon>{{ icons.mdiPause }}</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="item.statusSchedule === 'Pending'"
                    class="action-btn"
                    outlined
                    :style="isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
                    color="#F15858"
                    @click="deleteItem(item.id)"
                  >
                    <v-icon>{{ icons.mdiPlayOutline }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <!-- Buttons action Shown on display size sm and down-->
              <v-menu v-else offset-y :close-on-content-click="true" :close-on-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark icon v-bind="attrs" v-on="on">
                    <v-icon>{{ icons.mdiDotsHorizontalCircle }}</v-icon>
                  </v-btn>
                </template>

                <v-list outlined>
                  <v-btn
                    icon
                    @click="
                      () => {
                        editSchedule(item.id)
                      }
                    "
                  >
                    <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  </v-btn>
                  <v-btn v-if="item.statusSchedule === 'Published'" icon @click="toStatistic(item)">
                    <v-icon>{{ icons.mdiPoll }}</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="item.statusSchedule === 'Published'"
                    class="action-btn"
                    outlined
                    :style="isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
                    color="#F15858"
                    @click="deleteItem(item.id)"
                  >
                    <v-icon>{{ icons.mdiPause }}</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="item.statusSchedule === 'Pending'"
                    class="action-btn"
                    outlined
                    :style="isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
                    color="#F15858"
                    @click="deleteItem(item.id)"
                  >
                    <v-icon>{{ icons.mdiPlayOutline }}</v-icon>
                  </v-btn>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:item.statusSchedule="{ item }">
              <v-row justify="center" align="center">
                <v-col v-if="item.statusSchedule === 'Published'" align-self="start">
                  <p style="color: green">
                    {{ item.statusSchedule }}
                  </p>
                </v-col>
                <v-col v-if="item.statusSchedule === 'Pending'" align-self="start">
                  <p style="color: red">
                    {{ item.statusSchedule }}
                  </p>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
          <!-- End List View -->
        </transition>
      </v-col>

      <v-spacer />

      <!-- Start Side bar for created post -->
      <post-content-list v-if="$vuetify.breakpoint.lgAndUp" />
      <!-- End Side bar for created post -->
    </v-row>
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import {
  mdiPlus,
  mdiTune,
  mdiCalendarMonthOutline,
  mdiFormatListText,
  mdiMagnify,
  mdiFilterVariant,
  mdiClose,
  mdiPoll,
  mdiSquareEditOutline,
  mdiTrashCanOutline,
  mdiDotsHorizontalCircle,
  mdiPause,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import PostContentList from './components/PostContentList.vue'
import EditCampaignDialog from '../components/dialog/CreateCampaignDialog.vue'
import Calendar from './components/Calendar.vue'

export default {
  name: 'MarketingCalendar',
  components: { PostContentList, EditCampaignDialog, Calendar },
  setup() {
    const { isDark } = useAppConfig()
    const search = ref('')
    const searchDetail = ref('')
    const statusColor = {
      Processing: '#FBF4E9',
      Done: '#E7F7EE',
      Cancelled: '#F7E5E6',
      Draft: '#707683',
      Waiting: '#E9F5F5',
    }
    const statusColorText = {
      Processing: '#FFB946',
      Done: '#2ED47A',
      Cancelled: '#D11A2A',
      Draft: '#707683',
      Waiting: '#F15858',
    }

    return {
      icons: {
        mdiPlus,
        mdiTune,
        mdiCalendarMonthOutline,
        mdiFormatListText,
        mdiMagnify,
        mdiFilterVariant,
        mdiClose,
        mdiPoll,
        mdiSquareEditOutline,
        mdiTrashCanOutline,
        mdiDotsHorizontalCircle,
        mdiPause,
      },
      search,
      isDark,
      searchDetail,
      statusColor,
      statusColorText,
    }
  },
  data: () => ({
    // Toggle calendar/ list view
    toggle_exclusive: 0,

    // Table
    tableHeaders: [
      { text: 'No', value: 'id' },
      { text: 'Name post', value: 'post_name' },
      { text: 'Post time', value: 'date' },
      { text: 'Score', value: 'score' },
      { text: 'Status', value: 'statusSchedule' },
      { text: 'Action', value: 'actions', sortable: false },
    ],

    showEditCampaignModal: false,
    sortBy: 'post_name',
    sortDesc: true,
    postTemplates: [],
  }),
  computed: {
    schedules() {
      return this.$store.getters['marketingCalendar/getSchedules']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    campaignId() {
      return this.$route.params.id
    },
    posts() {
      return this.$store.getters['marketingCalendar/getPostTemplates']
    },
  },
  created() {
    this.$store.dispatch('marketingCalendar/fetchPostTemplates', {
      sub_id: this.user.sub_id,
      data: {
        idCampaign: this.campaignId,
      },
      token: this.user.token,
    })
    this.$store.dispatch('marketingCalendar/fetchSchedulesByIdCampaign', {
      sub_id: this.user.sub_id,
      data: {
        idCampaign: this.campaignId,
      },
      token: this.user.token,
    })
  },
  methods: {
    async editSchedule(id) {
      this.$router.push({ name: 'edit-post', params: { postId: id, page: 'Edit Post' } })
    },
    async campaignSettings() {
      await this.$store.dispatch('campaign_manager/fetchSpecifiedCampaign', {
        ID_for_fetching: this.campaignId,
      })
      this.showEditCampaignModal = true
    },
    toStatistic(scheduled) {
      //console.log(scheduled, ':id')
      this.$store.dispatch('marketingCalendar/fetchAnalytics', {
        idScheduleAyrshare: scheduled.dataFromAyrshare[0].id,
        platforms: scheduled.channels.filter(el => el.type && el.type !== 'WhatsApp').map(elm => elm.type),
      })

      // this.$router.push({ path: `/campaign-manager/${id}/statistic` })
    },
  },
}
</script>

<style lang="scss" scoped>
.cmd-button {
  font-weight: 500;
  font-size: 14px;
}

.text-capitalize {
  text-transform: capitalize;
}

.v-btn.action-btn {
  width: 36px;
  min-width: 36px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
